<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.4)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="60"
            :width="7"
            color="primary"
          >
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <div class="pb-10 grey--text text--darken-1 display-1">
            {{ $t("meeting_minutes_form.offline_info") }}
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            :v-if="isOnline"
            color="primary"
            dark
            @click="actualizationData()"
          >
            {{ $t("meeting_minutes_form.actualization_data") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <div class="pt-6 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.info") }}
        </p>
      </div>

      <v-row class="pb-6">
        <v-col cols="12" md="12">
          <v-combobox
            clearable
            v-model="selectProject"
            prepend-icon="mdi-assistant"
            :items="itemProject"
            :label="$t('meeting_minutes_form.project')"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5">
          <v-text-field
            prepend-icon="mdi-shape-outline"
            v-model="textTopic"
            :label="$t('meeting_minutes_form.topic')"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="5">
          <v-combobox
            clearable
            v-model="selectOvm"
            prepend-icon="mdi-home"
            :items="itemOvm"
            :label="$t('meeting_minutes_form.ovm')"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('meeting_minutes_form.date_title')"
                prepend-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="date"
              locale="sk-SK"
              scrollable
              @change="menuDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuTimeBegin"
            v-model="menuTimeBegin"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="timeBegin"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeBegin"
                :label="$t('meeting_minutes_form.time_begin')"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              format="24hr"
              locale="sk-SK"
              v-if="menuTimeBegin"
              v-model="timeBegin"
              full-width
              @click:minute="$refs.menuTimeBegin.save(timeBegin)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuTimeEnd"
            v-model="menuTimeEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="timeEnd"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeEnd"
                :label="$t('meeting_minutes_form.time_end')"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-time-picker
              format="24hr"
              locale="sk-SK"
              v-if="menuTimeEnd"
              v-model="timeEnd"
              full-width
              @click:minute="$refs.menuTimeEnd.save(timeEnd)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" md="3">
          <v-combobox
            clearable
            v-model="selectVersion"
            prepend-icon="mdi-lead-pencil"
            :items="itemVersion"
            :label="$t('meeting_minutes_form.version')"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <v-combobox
            clearable
            class="mt-6"
            v-model="selectProcessedPerson"
            prepend-icon="mdi-account"
            :items="itemPersons"
            :label="$t('meeting_minutes_form.who_processed')"
          ></v-combobox>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="6">
          <v-combobox
            clearable
            class="mt-6"
            v-model="selectApprovedPerson"
            prepend-icon="mdi-account"
            :items="itemPersons"
            :label="$t('meeting_minutes_form.who_approved')"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row class="pb-6">
        <v-col cols="12" md="12">
          <v-text-field
            prepend-icon="mdi-google-maps"
            v-model="textPlace"
            :label="$t('meeting_minutes_form.place')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <div class="pt-6 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.user_on_meetings") }}
        </p>
      </div>

      <v-row justify="center">
        <v-dialog v-model="dialogSave" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("meeting_minutes_form.save_successful")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSave = false">
                {{ $t("meeting_minutes_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogSaveWord" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("task_form.save_word_successful")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSaveWord = false">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-col cols="2" class="pt-7">
        <!--          Add Svg Icon-->
        <svg
          v-show="participantsList.length == 0"
          @click="addFirstFieldParticipantList()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#6cc24a"
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
      </v-col>

      <v-row
        class=""
        v-for="(input, index) in participantsList"
        :key="`participantsLists-${index}`"
      >
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="10">
              <v-combobox
                clearable
                class="mt-6"
                v-model="input.select"
                prepend-icon="mdi-account"
                :items="itemPersons"
                :label="$t('meeting_minutes_form.name')"
              ></v-combobox>
            </v-col>

            <v-col cols="2" class="pt-12" v-show="isEditing == 0">
              <!--          Add Svg Icon-->
              <svg
                @click="addFieldParticipantList()"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#6cc24a"
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>

              <!--          Remove Svg Icon-->
              <svg
                @click="removeFieldParticipantList(index)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#ed9b33"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="pt-8">
        <v-divider></v-divider>
      </div>

      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.agenda") }}
        </p>
      </div>

      <v-textarea
        auto-grow
        outlined
        label="Zadajte text."
        v-model="editorAgenda"
      ></v-textarea>

      <div class="pt-8">
        <v-divider></v-divider>
      </div>
      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.to_do_list") }}
        </p>
      </div>

      <v-col cols="2" class="pt-7">
        <!--          Add Svg Icon-->
        <svg
          v-show="toDoLists.length == 0"
          @click="addFirstField()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#6cc24a"
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
      </v-col>

      <v-row
        class="mb-32"
        v-for="(input, index) in toDoLists"
        :key="`toDoLists-${index}`"
      >
        <v-col cols="12" lg="1" class="mt-4 text-center">
          <span class="text-h6">{{ index + 1 }} )</span>
        </v-col>
        <v-col cols="12" lg="5">
          <v-combobox
            clearable
            v-model="input.selectTaskType"
            prepend-icon="mdi-android-messages"
            :items="itemTaskType"
            :label="$t('meeting_minutes_form.task_type')"
            :change="onChangeTaskType(index)"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="6" v-if="input.showStatus">
          <v-combobox
            clearable
            v-model="input.selectStatus"
            prepend-icon="mdi-list-status"
            :items="itemStatus"
            :label="$t('meeting_minutes_form.status')"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="6" v-if="input.showDate">
          <v-menu
            ref="menu"
            v-model="input.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="input.deadline"
                :label="$t('meeting_minutes_form.deadtime')"
                clearable
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="input.date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="input.deadline"
              locale="sk-SK"
              scrollable
              @change="input.menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="11" lg="6" v-if="input.showResponsible">
          <v-combobox
            v-model="input.responsible"
            prepend-icon="mdi-account"
            :items="itemPersons"
            :label="$t('meeting_minutes_form.responsible')"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="12">
          <v-textarea
            auto-grow
            prepend-icon="mdi-chart-box-outline"
            outlined
            :label="$t('meeting_minutes_form.task_name')"
            v-model="input.name"
          ></v-textarea>
        </v-col>

        <v-col cols="2" class="pt-7" v-show="isEditing == 0">
          <!--          Add Svg Icon-->
          <svg
            @click="addField(input)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#6cc24a"
              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>

          <!--          Remove Svg Icon-->
          <svg
            @click="removeField(index)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#ed9b33"
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            />
          </svg>
        </v-col>

        <v-col cols="3" class="text-right">
          <v-btn
            v-if="input.id"
            :disabled="isEditing != 1"
            dense
            color="primary"
            @click="concreteTask(index)"
          >
            {{ $t("meeting_minutes_form.continue_task") }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row class="pb-16 pt-6">
        <v-spacer></v-spacer>

        <v-col class="text-center" cols="12" lg="3">
          <v-btn color="primary" @click="saveOffline()">
            {{ $t("meeting_minutes_form.offline") }}
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="12" lg="3">
          <v-btn color="primary" @click="saveWord()">
            {{ $t("meeting_minutes_form.word") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>


<script>
// import { VueEditor } from "vue2-editor";
import axios from "axios";
import docxtemplater from "docxtemplater";
import jszip from "jszip";

// import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import Vue from "vue";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";

import localOvm from "@/assets/storage/ovm.json";
import localPerson from "@/assets/storage/person.json";
import localProject from "@/assets/storage/project.json";
import localStatus from "@/assets/storage/status.json";
import localTaskType from "@/assets/storage/taskType.json";
import localVersion from "@/assets/storage/version.json";

import { mapGetters } from "vuex";
import { VueOfflineStorage } from "vue-offline";
import VueOffline from "vue-offline";

Vue.use(VueOffline);

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);

export default {
  // components: {
  //   VueEditor,
  // },
  data: () => ({
    id_token: "",
    idm: "",
    admin: false,
    canEdit: false,
    urlVueFileAgent: "",
    toDoLists: [
      {
        name: "",
        responsible: "",
        deadline: null,
        menu: false,
        actual: "",
        addUser: false,
        showTaskType: false,
        showDate: false,
        showStatus: false,
        showResponsible: false,
      },
    ],

    fileRecords: [],

    isEditing: false,
    loading: false,
    actualLoading: 0,
    errorAxios: false,
    meetingID: null,
    loadExistMeetings: false,
    editingID: "",
    actualRow: "",
    textPlace: "",
    textPosition: "",
    textOrganization: "",
    actualIndex: 0,

    textName: "",
    textEmail: "",
    textTopic: "",
    editorAgenda: "",
    personType: "",
    itemOvm: [],
    itemOvmObj: [],
    itemProject: [],
    itemTaskType: [],
    itemPersons: [],
    itemVersion: [],
    itemStatusObj: [],
    itemStatus: [],
    itemVersionObj: [],
    itemProjectObj: [],
    itemTaskTypeObj: [],
    selectNumber: [],
    itemNumberActual: [],
    itemNumber: [],
    selectVersion: "",
    initVersion: "",
    selectOvm: "",
    selectTaskType: "",
    selectStatus: "",
    selectOvmAddUser: "",
    selectApprovedPerson: "",
    selectProject: "",
    selectProcessedPerson: "",
    selectPerson: "",

    actualApprovedPerson: "",
    actualProcessedPerson: "",
    actualPerson: "",

    valid: false,
    requiredField: [(v) => !!v || "Toto pole je potrebné vyplniť"],

    date: null,
    menuDate: false,
    menuTimeBegin: false,
    menuTimeEnd: false,
    menuDateToDo: false,
    timeBegin: null,
    timeEnd: null,

    dialogNewPerson: false,

    dialogDeletRow: false,
    dialogError: false,
    dialogSave: false,
    dialogSaveWord: false,
    headersParticipants: [
      { text: "Meno a priezvisko", value: "name" },
      { text: "Pozícia", value: "position" },
      { text: "Organizácia", value: "ovm.name" },
    ],
    participants: [],
    participantsList: [{ actual: "", select: "", addUser: false }],
    persons: [],
  }),
  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken"]),
  },
  methods: {
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    loadData() {
      let offlineDataOvm = "";
      if (VueOfflineStorage.get("ovm"))
        offlineDataOvm = VueOfflineStorage.get("ovm");
      let offlineDataPerson = "";
      if (VueOfflineStorage.get("persons"))
        offlineDataPerson = VueOfflineStorage.get("persons");
      let offlineDataProject = "";
      if (VueOfflineStorage.get("projects"))
        offlineDataProject = VueOfflineStorage.get("projects");
      let offlineDataStatus = "";
      if (VueOfflineStorage.get("status"))
        offlineDataStatus = VueOfflineStorage.get("status");
      let offlineDataTaskType = "";
      if (VueOfflineStorage.get("taskTypes"))
        offlineDataTaskType = VueOfflineStorage.get("taskTypes");
      let offlineDataVersion = "";
      if (VueOfflineStorage.get("versions"))
        offlineDataVersion = VueOfflineStorage.get("versions");

      this.id_token = this.getToken;

      if (!offlineDataOvm || offlineDataOvm == "")
        offlineDataOvm = this.itemOvmObj = localOvm;
      else this.itemOvmObj = offlineDataOvm;
      if (!offlineDataPerson || offlineDataPerson == "")
        offlineDataPerson = this.persons = localPerson;
      else this.persons = offlineDataPerson;
      if (!offlineDataProject || offlineDataProject == "")
        offlineDataProject = this.itemProjectObj = localProject;
      else this.itemProjectObj = offlineDataProject;
      if (!offlineDataStatus || offlineDataStatus == "")
        offlineDataStatus = this.itemStatusObj = localStatus;
      else this.itemStatusObj = offlineDataStatus;
      if (!offlineDataTaskType || offlineDataTaskType == "")
        offlineDataTaskType = this.itemTaskTypeObj = localTaskType;
      else this.itemTaskTypeObj = offlineDataTaskType;
      if (!offlineDataVersion || offlineDataVersion == "")
        offlineDataVersion = this.itemVersionObj = localVersion;
      else this.itemVersionObj = offlineDataVersion;

      this.itemVersion = [
        ...new Set(this.itemVersionObj.map((item) => item.name)),
      ];
      this.itemVersion.sort();
      this.itemOvm = [...new Set(this.itemOvmObj.map((item) => item.name))];
      this.itemOvm.sort();
      this.itemTaskType = [
        ...new Set(this.itemTaskTypeObj.map((item) => item.name)),
      ];
      this.itemTaskType.sort();
      this.itemProject = [
        ...new Set(this.itemProjectObj.map((item) => item.name)),
      ];
      this.itemProject.sort();
      this.itemStatus = [
        ...new Set(this.itemStatusObj.map((item) => item.name)),
      ];
      this.itemStatus.sort();
      this.itemPersons = this.persons
        .filter((p) => {
          return p.exist == 1;
        })
        .map(function (p) {
          return p.name + ", " + p.position + ", " + p.ovm.name;
        });
      this.itemPersons.sort();
    },

    onChangeTaskType(index) {
      this.toDoLists[index].uid = index + 1;
      if (this.toDoLists[index].selectTaskType == "U – úloha") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = true;
        this.toDoLists[index].showStatus = true;
        this.toDoLists[index].showResponsible = true;
      }
      if (this.toDoLists[index].selectTaskType == "R – riziko") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "O – otvorená") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = true;
        this.toDoLists[index].showStatus = true;
        this.toDoLists[index].showResponsible = true;
      }

      if (this.toDoLists[index].selectTaskType == "D – dohoda") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "I – informácia") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "P – predpoklad") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }

      if (this.toDoLists[index].selectTaskType == "V – výstup") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
    },

    axiosCatchPart(e) {
      this.completeLoadingBlock();
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response && e.response.status && e.response.status == 403)
        this.$router.push("login").catch(() => {});
    },

    addField() {
      this.toDoLists.push({
        name: "",
        responsible: "",
        actual: "",
        addUser: false,
        deadline: null,
        menu: false,
        showTaskType: false,
        showDate: false,
        showStatus: false,
        showResponsible: false,
      });
    },
    addFirstField() {
      this.toDoLists = [
        {
          name: "",
          responsible: "",
          actual: "",
          addUser: false,
          deadline: null,
          menu: false,
          showTaskType: false,
          showDate: false,
          showStatus: false,
          showResponsible: false,
        },
      ];
    },
    removeField(index) {
      this.toDoLists.splice(index, 1);
    },

    addFieldParticipantList() {
      this.participantsList.push({ actual: "", select: "", addUser: false });
    },
    addFirstFieldParticipantList() {
      this.participantsList = [{ actual: "", select: "", addUser: false }];
    },
    removeFieldParticipantList(index) {
      this.participantsList.splice(index, 1);
    },

    getActualJson() {
      let dateHelp = "";
      let timeBeginHelp = "";
      let timeEndHelp = "";

      if (this.date)
        dateHelp = this.date.substring(0, 10) + "T00:00:00.000+00:00";
      if (this.timeBegin)
        timeBeginHelp =
          this.date.substring(0, 10) +
          "T" +
          this.timeBegin.substring(0, 6) +
          ":00.000+00:00";
      if (this.timeEnd)
        timeEndHelp =
          this.date.substring(0, 10) +
          "T" +
          this.timeEnd.substring(0, 6) +
          ":00.000+00:00";

      let json = {
        project: this.selectProject,
        ovm: this.selectOvm,
        processedBy: this.selectProcessedPerson,
        approvedBy: this.selectApprovedPerson,
        persons: this.participantsList,
        tasks: this.toDoLists,
        version: this.selectVersion,
        topic: this.textTopic,
        date: dateHelp,
        timeBegin: timeBeginHelp,
        timeEnd: timeEndHelp,
        agenda: this.editorAgenda,
        place: this.textPlace,
      };

      return json;
    },

    saveWord() {
      let offlineJson = this.getActualJson();
      console.log(offlineJson);
      if (offlineJson.date)
        offlineJson.date = offlineJson.date.substring(0, 10);
      if (offlineJson.timeBegin)
        offlineJson.timeBegin = offlineJson.timeBegin.substring(11, 16);
      if (offlineJson.timeEnd)
        offlineJson.timeEnd = offlineJson.timeEnd.substring(11, 16);
      offlineJson.project = [{ name: offlineJson.project }];
      offlineJson.ovm = [{ name: offlineJson.ovm }];
      offlineJson.version = [{ name: offlineJson.version }];
      offlineJson.processedBy = [{ name: offlineJson.processedBy }];
      offlineJson.approvedBy = [{ name: offlineJson.approvedBy }];

      offlineJson.persons = offlineJson.persons.map((obj) => {
        if (obj.select.split(", ").length > 0)
          obj.name = obj.select.split(", ")[0];
        else obj.name = obj.select;
        if (obj.select.split(", ").length > 1)
          obj.position = obj.select.split(", ")[1];
        if (obj.select.split(", ").length > 2)
          obj.ovm = [{ name: obj.select.split(", ")[2] }];
        return obj;
      });

      offlineJson.tasks.forEach(function (task) {
        if (task.deadline) {
          task.deadline = task.deadline.substring(0, 10);
        }
        if (task.selectTaskType) {
          task.taskType = [{ name: task.selectTaskType }];
        }
        if (task.selectStatus) {
          task.status = [{ name: task.selectStatus }];
        }
      });

      let offlineTopic = this.textTopic;
      let offlineVersion = this.selectVersion;
      JSZipUtils.getBinaryContent(
        "/ReportTemplates/meeting_template.docx",
        function (error, content) {
          if (error) {
            throw error;
          }
          let zip = new jszip(content);
          let doc = new docxtemplater(zip, {
            nullGetter() {
              return "-";
            },
            linebreaks: true,
          });
          doc.setData(offlineJson);
          try {
            doc.render();
          } catch (error) {
            let e = {
              message: error.message,
              name: error.name,
              stack: error.stack,
              properties: error.properties,
            };
            throw error;
          }
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          // docx generating
          let out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });

          let namefile =
            "Zápis_" +
            offlineTopic +
            "_" +
            yyyy +
            mm +
            dd +
            "_" +
            offlineVersion +
            ".docx";
          namefile = namefile.replaceAll(" ", "_");
          saveAs(out, namefile); // You can pass this blob to a custom file saver component in the project.
        }
      );
      this.dialogSaveWord = true;
    },

    saveOffline() {
      let offlineJson = this.getActualJson();
      // let offlineJson = this.getActualOfflineJson();
      var file = new Blob([JSON.stringify(offlineJson)], {
        type: "text/plain;charset=utf-8",
      });
      let namefile = "Zápis_DI_" + this.textTopic + "_ver.json";
      saveAs(file, namefile);
      this.dialogSave = true;
    },

    findOvmByName(name) {
      return this.itemOvmObj.filter((p) => p.name === name);
    },
    async actualizationData() {
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      await Promise.all([
        this.loadPersons(),
        this.loadVersion(),
        this.loadOvm(),
        this.loadProject(),
        this.loadTaskType(),
        this.loadStatus(),
      ]);
      this.loadData();
      // this.$router.go(0)
    },
    loadVersion() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_VERSION, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.saveOfflineMode("versions", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },
    loadOvm() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_OVM, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.saveOfflineMode("ovm", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadTaskType() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASKTYPE, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.saveOfflineMode("taskTypes", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadProject() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PROJECT, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.saveOfflineMode("projects", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadStatus() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_STATUS, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.saveOfflineMode("status", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          // console.log(response.data);
          this.saveOfflineMode("persons", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
  },
};
</script>


<style scoped>
#profile-pic-demo .drop-help-text {
  display: none;
}
#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}
#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #aaa;
}
#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}
</style>