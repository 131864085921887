<template>
  <div>
    <NavBar />
    <offline-meeting-minutes />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import OfflineMeetingMinutes from '../components/OfflineMeetingMinutes.vue';

export default {
  name: "OfflineMeeting",

  components: {
    NavBar,
    OfflineMeetingMinutes,
  },
};
</script>

    OfflineMeetingMinutes